// Chakra imports
import {
  Flex,
  Heading,
  Icon,
  IconButton,
  SimpleGrid,
  useColorModeValue,
  Grid,
  GridItem,
  Progress,
  Box,
  Text,
  Image,
  Center,
} from "@chakra-ui/react";
// Assets
// Custom components
import { ViewIcon } from "@chakra-ui/icons";
import Card from "components/card/Card";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { HSeparator } from "components/separator/Separator";
import { useEffect, useState } from "react";
import { LuBuilding2 } from "react-icons/lu";
import { MdAddTask, MdContacts, MdLeaderboard } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/api";
import { toast } from "react-toastify";
import ReportChart from "../reports/components/reportChart";
import Chart from "components/charts/LineChart.js";
// import Chart from "../reports/components/chart";
import benthon from "../../../Images/benthon.png";
import { HasAccess } from "../../../redux/accessUtils";
import PieChart from "components/charts/PieChart";
import CountUpComponent from "../../../components/countUpComponent/countUpComponent";
import Spinner from "components/spinner/Spinner";
import { useMsal } from "@azure/msal-react";
import { graphConfig, accessRequest } from "auth-config";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import CustomView from "components/CustomView/CustomView";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const user = JSON.parse(localStorage.getItem("user"));
  const [isLoding, setIsLoding] = useState(false);

  const [task, setTask] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const navigate = useNavigate();
  const [customData, setCustomData] = useState({});
  const [barData, setBarData] = useState({});

  const [
    contactsView,
    taskView,
    leadView,
    proprtyView,
    emailView,
    callView,
    meetingView,
  ] = HasAccess([
    "Contacts",
    "Tasks",
    "Leads",
    "Properties",
    "Emails",
    "Calls",
    "Meetings",
  ]);
  async function customViewCall() {
    let res;
    try {
      res = await getApi("api/custom-view/list");
      if (res.data) setCustomData(res.data);
      else toast.error("Something went wrong");
    } catch (e) {
      toast.error("Something went wrong");
    }
  }
 
   
  async function barChartData() {
    let res;
    let updatedBarData = {};
    res = await getApi("api/custom-view/chart");

    if (res && res.data && res.data.charData) {
      updatedBarData = res.data.charData.map((item) => {
        return {
          name: item._id,
          length: item.count,
          color: "blue",
        };
      });
      data.charData = updatedBarData;
      setBarData(data);
    }
  }
  useEffect(() => {
    customViewCall();
    barChartData();
  }, []);

  const fetchData = async () => {
    let taskData;
    setTimeout(async () => {
      if (user?.role === "superAdmin") {
        taskData = await getApi("api/task/");
      } else if (
        taskView?.create ||
        taskView?.update ||
        taskView?.delete ||
        taskView?.view
      ) {
        taskData = await getApi(`api/task/?createBy=${user?._id}`);
      }
      setTask(taskData?.data);
      // }, 4000);
      let contact;
      // setTimeout(async () => {
      if (user?.role === "superAdmin") {
        contact = await getApi("api/contact/");
      } else if (
        contactsView?.create ||
        contactsView?.update ||
        contactsView?.delete ||
        contactsView?.view
      ) {
        contact = await getApi(`api/contact/?createBy=${user?._id}`);
      }
      setContactData(contact?.data);
      // }, 4000);
      let lead;
      // setTimeout(async () => {
      if (user?.role === "superAdmin") {
        lead = await getApi("api/lead/");
      } else if (
        leadView?.create ||
        leadView?.update ||
        leadView?.delete ||
        leadView?.view
      ) {
        lead = await getApi(`api/lead/?createBy=${user?._id}`);
      }
      setLeadData(lead?.data);
      // }, 4000);
      // let property;
      // setTimeout(async () => {
      // if (user?.role === "superAdmin") {
      //   property = await getApi("api/property/")
      // } else if (proprtyView?.create || proprtyView?.update || proprtyView?.delete || proprtyView?.view) {
      //   property = await getApi(`api/property/?createBy=${user?._id}`)
      // }
      // setPropertyData(property?.data);
    }, 3000);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchProgressChart = async () => {
    setIsLoding(true);
    let result = await getApi(
      user?.role === "superAdmin"
        ? "api/reporting/line-chart"
        : `api/reporting/line-chart?createBy=${user?._id}`
    );
    if (result && result?.status === 200) {
      setData(result?.data);
    }
    setIsLoding(false);
  };
  useEffect(() => {
    fetchProgressChart();
  }, []);

  const taskStatus = [
    {
      name: "Completed",
      status: "completed",
      length:
        (task &&
          task?.length > 0 &&
          task?.filter((item) => item?.status === "completed")?.length) ||
        0,
      color: "#4d8f3a",
    },
    {
      name: "Pending",
      status: "pending",
      length:
        (task &&
          task?.length > 0 &&
          task?.filter((item) => item?.status === "pending")?.length) ||
        0,
      color: "#a37f08",
    },
    {
      name: "In Progress",
      status: "inProgress",
      length:
        (task &&
          task?.length > 0 &&
          task?.filter((item) => item?.status === "inProgress")?.length) ||
        0,
      color: "#7038db",
    },
    {
      name: "Todo",
      status: "todo",
      length:
        (task &&
          task?.length > 0 &&
          task?.filter((item) => item?.status === "todo")?.length) ||
        0,
      color: "#1f7eeb",
    },
    {
      name: "On Hold",
      status: "onHold",
      length:
        (task &&
          task?.length > 0 &&
          task?.filter((item) => item?.status === "onHold")?.length) ||
        0,
      color: "#DB5436",
    },
  ];
  const navigateTo = {
    Lead: "/crm/lead",
    Contact: "/crm/contacts",
    Meeting: "/crm/metting",
    Call: "/crm/phone-call",
    Task: "/crm/task",
    Email: "/crm/email",
    Property: "/crm/properties",
  };
 
  return (
    <Flex>
      <Center w="100vw" h={"100vh"}>
        <Box w={"400px"} h={"400px"}>
          <Image
            objectFit="cover"
            src={benthon}
            style={{ opacity: "0.2" }}
            h="100%"
            w="100%"
          />
        </Box>
      </Center>
    </Flex>
  );
}
