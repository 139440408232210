
const saveDataToStorage =  (key, value) => {
  try {
    localStorage.setItem(key, value);
    return {msg: 'Data saved successfully!'};
  } catch (error) {
    return error;
  }
};
const getDataFromStorage =  (key) => {
  try {
    const value =  localStorage.getItem(key);
    if (value !== null) {
      return value; // Return the retrieved data
    } else {
      return null; // Return null indicating no data found
    }
  } catch (error) {
    return null; // Return null indicating error
  }
};
const removeDataFromStorage =  (key) => {
  try {
     localStorage.removeItem(key);
    return 'Data removed successfully';
  } catch (error) {
    return error;
  }
};
export {saveDataToStorage, getDataFromStorage, removeDataFromStorage};
