// Chakra imports
import { Portal, Box, useDisclosure, Flex, Icon } from "@chakra-ui/react";
import Footer from "components/footer/FooterAdmin.js";
// Layout components
import Navbar from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import Spinner from "components/spinner/Spinner";
import { SidebarContext } from "contexts/SidebarContext";
import React, { Suspense, useEffect } from "react";
import { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ROLE_PATH } from "../../roles";
import { homeRoutes } from "routes.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchImage } from "../../redux/imageSlice";
import { getApi } from "services/api";
import { MdHome, MdLock } from "react-icons/md";
import DynamicPage from "views/admin/dynamicPage";
import DynamicPageview from "views/admin/dynamicPage/DynamicPageview";
import { fetchRouteData } from "../../redux/routeSlice";
import { LuChevronRightCircle } from "react-icons/lu";
// import { homeRoutes } from "routes";
import newRoutes from "routes.js";
import { vaultRoutes } from "routes";
import { commonRoutes } from "routes";
import { departmentRoutes } from "routes";
// import { anotherRoutes } from "routes";
const MainDashboard = React.lazy(() => import("views/admin/default"));

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const dispatch = useDispatch();
  const location = useLocation();

  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openSubBar, setOpenSubBar] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  // let routes = newRoutes;
  const [subRoute, setSubRoute] = useState(
    location.pathname === "/home"
      ? []
      : location.pathname.split("/").includes("crm")
      ? newRoutes
      : location.pathname.split("/").includes("password")
      ? vaultRoutes
      : location.pathname.split("/").includes("department")?departmentRoutes:commonRoutes
  );

  const [routes, setRoutes] = useState(homeRoutes);
  const route = useSelector((state) => state?.route?.data);
  const pathName = (name) => {
    return `/${name.toLowerCase().replace(/ /g, "-")}`;
  };


  useEffect(() => {
    if (location.pathname === "/home") {
      setSubRoute([]);
      // subRoute = [];
    } else if (location.pathname.split("/").includes("crm")) {
      dynamicRoute();
      setSubRoute(newRoutes);
      // subRoute = newRoutes;
    } else if (location.pathname.split("/").includes("password")) {
      setSubRoute(vaultRoutes);
      // selectedRoutes = vaultRoutes;
    }else  if (location.pathname.split("/").includes("department")) {
      setSubRoute(departmentRoutes);
      // selectedRoutes = vaultRoutes;
    }else {
      setSubRoute(commonRoutes);
      // selectedRoutes = commonRoutes;
    }
  }, [location.pathname]);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const dynamicRoute = () => {
    let apiData = [];

    route &&
      route?.length > 0 &&
      route?.map((item, i) => {
        let rec = subRoute.find((route) => route?.name === item?.moduleName);
        if (!subRoute.some((route) => route?.name === item?.moduleName)) {
          const newRoute = [
            {
              name: item?.moduleName,
              layout: [ROLE_PATH.superAdmin],
              path: pathName(item.moduleName),
              icon: item?.icon ? (
                <img src={item?.icon} width="20px" height="20px" alt="icon" />
              ) : (
                <Icon
                  as={LuChevronRightCircle}
                  width="20px"
                  height="20px"
                  color="inherit"
                />
              ),
              component: DynamicPage,
            },
            {
              name: item?.moduleName,
              layout: [ROLE_PATH.superAdmin],
              under: item?.moduleName,
              parentName: item?.moduleName,
              path: `${pathName(item.moduleName)}/:id`,
              icon: item?.icon ? (
                <img src={item?.icon} width="20px" height="20px" alt="icon" />
              ) : (
                <Icon
                  as={LuChevronRightCircle}
                  width="20px"
                  height="20px"
                  color="inherit"
                />
              ),
              component: DynamicPageview,
            },
          ];
          setSubRoute((pre) => [...pre, ...newRoute]);
        } else if (
          subRoute.some((route) => route?.name === item?.moduleName) &&
          rec.icon?.props?.src !== item?.icon
        ) {
          const updatedData = subRoute?.map((i) => {
            if (i.name === item?.moduleName) {
              return {
                ...i,
                icon: (
                  <img src={item?.icon} width="20px" height="20px" alt="icon" />
                ),
              };
            }
            return i;
          });
          setSubRoute(updatedData);
        }
        if (
          subRoute.find((route) => {
            return route?.name !== item?.moduleName;
          })
        ) {
          if (
            !newRoutes.find((route) => {
              return (
                route?.name?.toLowerCase() === item?.moduleName?.toLowerCase()
              );
            })
          ) {
            const newRoute = [
              {
                name: item?.moduleName,
                layout: [ROLE_PATH.superAdmin],
                path: pathName(item.moduleName),
                icon: item?.icon ? (
                  <img src={item?.icon} width="20px" height="20px" alt="icon" />
                ) : (
                  <Icon
                    as={LuChevronRightCircle}
                    width="20px"
                    height="20px"
                    color="inherit"
                  />
                ),
                component: DynamicPage,
              },
              {
                name: item?.moduleName,
                layout: [ROLE_PATH.superAdmin],
                under: item?.moduleName,
                parentName: item?.moduleName,
                path: `${pathName(item.moduleName)}/:id`,
                icon: item?.icon ? (
                  <img src={item?.icon} width="20px" height="20px" alt="icon" />
                ) : (
                  <Icon
                    as={LuChevronRightCircle}
                    width="20px"
                    height="20px"
                    color="inherit"
                  />
                ),
                component: DynamicPageview,
              },
            ];
            apiData.push(...newRoute);
          }
        }
      });

    let filterData = [...newRoutes, ...apiData];
    setSubRoute(filterData);
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "pulseCRM pro";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].path.replace("/:id", "")) !==
          -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  useEffect(() => {
    if (location.pathname.includes("crm")) dynamicRoute();
  }, [route,location.pathname]);

  useEffect(async () => {
    await dispatch(fetchRouteData());
    await dispatch(fetchImage());
  }, []);

  const largeLogo = useSelector((state) =>
    state?.images?.image?.filter((item) => item.isActive === true)
  );

  const under = (routes) => {
    let activeRoute = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].path.replace("/:id", "")) !==
          -1
        ) {
          return routes[i];
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (!prop.under && prop.layout?.includes(ROLE_PATH.superAdmin)) {
        return (
          <Route path={prop.path} element={<prop.component />} key={key} />
        );
      } else if (prop.under) {
        return (
          <Route path={prop.path} element={<prop.component />} key={key} />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  document.documentElement.dir = "ltr";
  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar
            subRoute={subRoute}
            routes={routes}
            largeLogo={largeLogo}
            display="none"
            {...rest}
            openSubBar={openSubBar}
            setOpenSubBar={setOpenSubBar}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
          />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            // w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
            w={{
              base: "100%",
              xl:
                openSidebar === true
                  ? "calc( 100% - 300px )"
                  : openSubBar === true
                  ? "calc( 100% - 500px )"
                  : !openSidebar && !openSubBar
                  ? "calc( 100% - 88px )"
                  : "calc( 100% - 200px )",
            }}
            maxWidth={{
              base: "100%",
              xl:
                openSidebar === true
                  ? "calc( 100% - 300px )"
                  : openSubBar === true
                  ? "calc( 100% - 500px )"
                  : !openSidebar && !openSubBar
                  ? "calc(100% - 150px)"
                  : "calc( 100% - 200px )",
            }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Portal>
              <Box className="header">
                <Navbar
                  onOpen={onOpen}
                  logoText={"Horizon UI Dashboard PRO"}
                  brandText={getActiveRoute(subRoute)}
                  secondary={getActiveNavbar(subRoute)}
                  message={getActiveNavbarText(subRoute)}
                  routes={routes}
                  subRoute={subRoute}
                  fixed={fixed}
                  // navbarRoutes={navbarRoutes}
                  under={under(subRoute)}
                  largeLogo={largeLogo}
                  openSubBar={openSubBar}
                  setOpenSubBar={setOpenSubBar}
                  openSidebar={openSidebar}
                  setOpenSidebar={setOpenSidebar}
                  {...rest}
                />
              </Box>
            </Portal>
            <Box pt={{ base: "150px", md: "95px", xl: "95px" }}>
              {getRoute() ? (
                <Box
                  mx="auto"
                  pe="20px"
                  w={"50%"}
                  minH="84vh"
                  pt="50px"
                  style={{
                    padding: openSidebar ? "8px 20px 8px 0px" : "8px 20px",
                  }}
                >
                  <Suspense
                    fallback={
                      <Flex
                        justifyContent={"center"}
                        alignItems={"center"}
                        width="100%"
                      >
                        <Spinner />
                      </Flex>
                    }
                  >
                    <Routes>
                      {getRoutes(location.pathname === "/home" ? homeRoutes : subRoute)}
                      <Route
                        path="/superAdmin"
                        element={<Navigate to="/home" />}
                      />
                    </Routes>
                  </Suspense>
                </Box>
              ) : null}
            </Box>
            <Box>
              <Footer />
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}
