import * as yup from 'yup'

export const loginSchema = yup.object({
    username: yup.string().email().required("Email Is required"),
    password: yup.string().required("Password Is required")
})

export const forgotPasswordEmailSchema = yup.object({
    email: yup.string().email().required("Email Is required"),
})
export const resetPasswordSchema=yup.object({
    newpassword: yup.string().required(),
    confirmnewpassword: yup.string().oneOf([yup.ref("newpassword"), null], "Passwords don't match").required(),
})