/* eslint-disable */
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
// chakra imports
import newRoutes from "routes.js";

import DynamicPage from "views/admin/dynamicPage";
import DynamicPageview from "views/admin/dynamicPage/DynamicPageview";
import {
  AbsoluteCenter,
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  styled,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { homeRoutes } from "routes";
import { vaultRoutes } from "routes";
import { commonRoutes } from "routes";
import { useSelector } from "react-redux";
import { ROLE_PATH } from "roles";
import { LuChevronRightCircle } from "react-icons/lu";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("brand.600", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.600", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  let sidebarBgColor = useColorModeValue("gray.200", "brand.200");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const user = JSON.parse(localStorage.getItem("user"));

  const { routes, setOpenSidebar, openSidebar, isNavbarMenu } = props;
  const [updatedRoute, setUpdatedRoute] = useState();
  const [selectedNavLinkInfo, setSelectedNavLinkInfo] = useState({
    selectedNavLink: false,
    selectedNavLinkId: "",
  });
  const navigate = useNavigate();
  const [crmSubRoute, setCrmSubRoute] = useState(newRoutes);
 
  const route = useSelector((state) => state?.route?.data);
  const pathName = (name) => {
    return `/${name.toLowerCase().replace(/ /g, "-")}`;
  };
  useEffect(() => {
    const navRouteList = homeRoutes.map((link) => {
      if (link.path.split("/").includes("crm")) {
        link["subRoutes"] = crmSubRoute;
      } else if (link.path.split("/").includes("password")) {
        link["subRoutes"] = vaultRoutes;
      } else if (link.path.split("/").includes("common")) {
        link["subRoutes"] = commonRoutes;
      } else {
        link["subRoutes"] = [];
      }
      return link;
    });
    setUpdatedRoute(isNavbarMenu ? [...new Set(navRouteList)] : routes);
  }, [location.pathname,props,crmSubRoute]);

  useEffect(() => {
    dynamicRoute();
  }, []);
  const [isLabelVisibleInfo, setIsLabelVisibleInfo] = useState({
    isLabelVisible:false,
    isLabelName:''
  })

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname?.includes(routeName);
  };

  const navbarLinkHandler = (route) => {
    if (route.path.split("/")[1] === "home") {
      navigate("/home");
      setSelectedNavLinkInfo({
        selectedNavLink: false,
        selectedNavLinkId: "",
      });
    } else
      setSelectedNavLinkInfo((prev) => ({
        selectedNavLink: !prev.selectedNavLink,
        selectedNavLinkId: route.path.split("/")[1],
      }));
  };
  const dynamicRoute = () => {
    let apiData = [];
    route &&
      route?.length > 0 &&
      route?.map((item, i) => {
        let rec = crmSubRoute.find((route) => route?.name === item?.moduleName);
        if (!crmSubRoute.some((route) => route?.name === item?.moduleName)) {
          const newRoute = [
            {
              name: item?.moduleName,
              layout: [ROLE_PATH.superAdmin],
              path: pathName(item.moduleName),
              icon: item?.icon ? (
                <img src={item?.icon} width="20px" height="20px" alt="icon" />
              ) : (
                <Icon
                  as={LuChevronRightCircle}
                  width="20px"
                  height="20px"
                  color="inherit"
                />
              ),
              component: DynamicPage,
            },
            {
              name: item?.moduleName,
              layout: [ROLE_PATH.superAdmin],
              under: item?.moduleName,
              parentName: item?.moduleName,
              path: `${pathName(item.moduleName)}/:id`,
              icon: item?.icon ? (
                <img src={item?.icon} width="20px" height="20px" alt="icon" />
              ) : (
                <Icon
                  as={LuChevronRightCircle}
                  width="20px"
                  height="20px"
                  color="inherit"
                />
              ),
              component: DynamicPageview,
            },
          ];
          setCrmSubRoute((pre) => [...pre, ...newRoute]);
        } else if (
          crmSubRoute.some((route) => route?.name === item?.moduleName) &&
          rec.icon?.props?.src !== item?.icon
        ) {
          const updatedData = crmSubRoute?.map((i) => {
            if (i.name === item?.moduleName) {
              return {
                ...i,
                icon: (
                  <img src={item?.icon} width="20px" height="20px" alt="icon" />
                ),
              };
            }
            return i;
          });
          setCrmSubRoute(updatedData);
        }
        if (
          crmSubRoute.find((route) => {
            return route?.name !== item?.moduleName;
          })
        ) {
          if (
            !newRoutes.find((route) => {
              return (
                route?.name?.toLowerCase() === item?.moduleName?.toLowerCase()
              );
            })
          ) {
            const newRoute = [
              {
                name: item?.moduleName,
                layout: [ROLE_PATH.superAdmin],
                path: pathName(item.moduleName),
                icon: item?.icon ? (
                  <img src={item?.icon} width="20px" height="20px" alt="icon" />
                ) : (
                  <Icon
                    as={LuChevronRightCircle}
                    width="20px"
                    height="20px"
                    color="inherit"
                  />
                ),
                component: DynamicPage,
              },
              {
                name: item?.moduleName,
                layout: [ROLE_PATH.superAdmin],
                under: item?.moduleName,
                parentName: item?.moduleName,
                path: `${pathName(item.moduleName)}/:id`,
                icon: item?.icon ? (
                  <img src={item?.icon} width="20px" height="20px" alt="icon" />
                ) : (
                  <Icon
                    as={LuChevronRightCircle}
                    width="20px"
                    height="20px"
                    color="inherit"
                  />
                ),
                component: DynamicPageview,
              },
            ];
            apiData.push(...newRoute);
          }
        }
      });

    let filterData = [...newRoutes, ...apiData];
    setCrmSubRoute(filterData);
  };


  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return (
      routes &&
      routes.length > 0 &&
      routes.map((route, index) => {
        if (route?.category) {
          return (
            <Box key={`category-${index}`}>
              <Text
                fontSize={"md"}
                color={activeColor}
                fontWeight="bold"
                mx="auto"
                ps={{
                  sm: "10px",
                  xl: "16px",
                }}
                pt="18px"
                pb="10px"
              >
                {route?.name}
              </Text>
              {createLinks(route?.items)}
            </Box>
          );
        } else if (
          !route?.under &&
          user?.role &&
          route?.layout?.includes(`/${user?.role}`) &&
          route.path != "/redirect_spa"
        ) {
          let isSubNavLinkVisible = false;
          if (
            route.path.split("/")[1] === selectedNavLinkInfo.selectedNavLinkId
          ) {
            isSubNavLinkVisible = true;
          }
  
          return (
            <Box key={`route-${route.path}`}>
              {!isNavbarMenu ? (
                <NavLink key={route.path} to={route.path}>
                  {route?.separator && (
                    <Box position="relative" margin="20px 0">
                      <Divider />
                      <AbsoluteCenter
                        textTransform={"capitalize"}
                        bg="white"
                        width={"max-content"}
                        padding="0 10px"
                        textAlign={"center"}
                      >
                        {route?.separator}
                      </AbsoluteCenter>
                    </Box>
                  )}
                  {route.icon ? (
                    <Box
                    className="sideTab"
                      backgroundColor={
                        activeRoute(route.path.toLowerCase())
                          ? sidebarBgColor
                          : ""
                      }
                      ps={"25px"}
                      pb={"6px"}
                      pt={"10px"}
                      onMouseEnter={() => setIsLabelVisibleInfo({isLabelName:route.name,isLabelVisible:true})}
                      onMouseLeave={() => setIsLabelVisibleInfo({isLabelName:'',isLabelVisible:false})}
                    >
                      <HStack 
                        spacing={
                          activeRoute(route.path.toLowerCase())
                            ? "22px"
                            : "26px"
                        }
                        py="5px"
                      >
                        {openSidebar === true ? (
                          <Flex                          
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Box
                              color={
                                activeRoute(route.path.toLowerCase())
                                  ? activeIcon
                                  : textColor
                              }
                              me="18px"
                            >
                              {route.icon}
                             
                            </Box>
                            <Text
                              me="auto"
                              pb={"3px"}
                              textOverflow={"ellipsis"}
                              overflowX="hidden"
                              whiteSpace="nowrap"
                              width="190px"
                              color={
                                activeRoute(route.path.toLowerCase())
                                  ? activeColor
                                  : textColor
                              }
                              fontWeight={
                                activeRoute(route.path.toLowerCase())
                                  ? "bold"
                                  : "normal"
                              }
                            >
                              <Tooltip hasArrow label={route.name}>
                                {route.name}
                              </Tooltip>
                            </Text>
                          </Flex>
                        ) : (
                          <Flex
                            w="100%"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Box
                              color={
                                activeRoute(route.path.toLowerCase())
                                  ? activeIcon
                                  : textColor
                              }
                              me="18px"
                             
                            >
                              {route.icon}
                              <div className="hoverName" style={{display:isLabelVisibleInfo.isLabelName===route.name && isLabelVisibleInfo ?'flex':'none'}} label={route.name}>{route.name}</div> 
                            </Box>
                          </Flex>
                        )}
                        <Box
                          w="4px"
                          bg={
                            activeRoute(route.path.toLowerCase())
                              ? brandColor
                              : brandColor
                          }
                          borderRadius="5px"
                        />
                      </HStack>
                    </Box>
                  ) : (
                    <Box>
                      <HStack
                        spacing={
                          activeRoute(route.path.toLowerCase())
                            ? "22px"
                            : "26px"
                        }
                        py="5px"
                        ps="10px"
                      >
                        <Text
                          me="auto"
                          color={
                            activeRoute(route.path.toLowerCase())
                              ? activeColor
                              : inactiveColor
                          }
                          fontWeight={
                            activeRoute(route.path.toLowerCase())
                              ? "bold"
                              : "normal"
                          }
                        >
                          {route.name}
                        </Text>
                        <Box
                          h="36px"
                          w="4px"
                          bg="brand.400"
                          borderRadius="5px"
                        />
                      </HStack>
                    </Box>
                  )}
                </NavLink>
              ) : (
                <Box cursor={"pointer"}>
                  <Box
                    key={`navbar-${route.path}`}
                    onClick={() => {
                      navbarLinkHandler(route);
                    }}
                  >
                    {route?.separator && (
                      <Box position="relative" margin="20px 0">
                        <Divider />
                        <AbsoluteCenter
                          textTransform={"capitalize"}
                          bg="white"
                          width={"max-content"}
                          padding="0 10px"
                          textAlign={"center"}
                        >
                          {route?.separator}
                        </AbsoluteCenter>
                      </Box>
                    )}
                    {route.icon ? (
                      <Box
                        backgroundColor={
                          activeRoute(route.path.toLowerCase())
                            ? sidebarBgColor
                            : ""
                        }
                        ps={"25px"}
                        pb={"6px"}
                        pt={"10px"}
                      >
                        <HStack
                          spacing={
                            activeRoute(route.path.toLowerCase())
                              ? "22px"
                              : "26px"
                          }
                          py="5px"
                        >
                          {openSidebar === true ? (
                            <Flex
                              w="100%"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Box
                                color={
                                  activeRoute(route.path.toLowerCase())
                                    ? activeIcon
                                    : textColor
                                }
                                me="18px"
                              >
                                {route.icon}
                              </Box>
                              <Text
                                me="auto"
                                pb={"3px"}
                                textOverflow={"ellipsis"}
                                overflowX="hidden"
                                whiteSpace="nowrap"
                                width="190px"
                                color={
                                  activeRoute(route.path.toLowerCase())
                                    ? activeColor
                                    : textColor
                                }
                                fontWeight={
                                  activeRoute(route.path.toLowerCase())
                                    ? "bold"
                                    : "normal"
                                }
                              >
                                <Tooltip hasArrow label={route.name}>
                                  {route.name}
                                </Tooltip>
                              </Text>
                            </Flex>
                          ) : (
                            <Flex
                              w="100%"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Box
                                color={
                                  activeRoute(route.path.toLowerCase())
                                    ? activeIcon
                                    : textColor
                                }
                                me="18px"
                              >
                                {route.icon}
                              </Box>
                            </Flex>
                          )}
                          <Box
                            w="4px"
                            bg={
                              activeRoute(route.path.toLowerCase())
                                ? brandColor
                                : brandColor
                            }
                            borderRadius="5px"
                          />
                        </HStack>
                      </Box>
                    ) : (
                      <Box>
                        <HStack
                          spacing={
                            activeRoute(route.path.toLowerCase())
                              ? "22px"
                              : "26px"
                          }
                          py="5px"
                          ps="10px"
                        >
                          <Text
                            me="auto"
                            color={
                              activeRoute(route.path.toLowerCase())
                                ? activeColor
                                : inactiveColor
                            }
                            fontWeight={
                              activeRoute(route.path.toLowerCase())
                                ? "bold"
                                : "normal"
                            }
                          >
                            {route.name}
                          </Text>
                          <Box
                            h="36px"
                            w="4px"
                            bg="brand.400"
                            borderRadius="5px"
                          />
                        </HStack>
                      </Box>
                    )}
                  </Box>
                  <Box pl={10}>
                    {selectedNavLinkInfo.selectedNavLink &&
                      isSubNavLinkVisible &&
                      route.subRoutes &&
                      route.subRoutes.length > 0 &&
                      route.subRoutes.map((link, subIndex) => {
                        return (
                          !link?.under &&
                          user?.role &&
                          link?.layout?.includes(`/${user?.role}`) &&
                          link.path != "/redirect_spa" && (
                            <NavLink key={`subRoute-${link.path}-${subIndex}`} to={link.path}>
                              {link?.separator && (
                                <Box position="relative" margin="20px 0">
                                  <Divider />
                                  <AbsoluteCenter
                                    textTransform={"capitalize"}
                                    bg="white"
                                    width={"max-content"}
                                    padding="0 10px"
                                    textAlign={"center"}
                                  >
                                    {link?.separator}
                                  </AbsoluteCenter>
                                </Box>
                              )}
                              {link.icon ? (
                                <Box
                                  backgroundColor={
                                    activeRoute(link.path.toLowerCase())
                                      ? sidebarBgColor
                                      : ""
                                  }
                                  ps={"25px"}
                                  pb={"6px"}
                                  pt={"10px"}
                                >
                                  <HStack
                                    spacing={
                                      activeRoute(link.path.toLowerCase())
                                        ? "22px"
                                        : "26px"
                                    }
                                    py="5px"
                                  >
                                    {openSidebar === true ? (
                                      <Flex
                                        w="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Box
                                          color={
                                            activeRoute(link.path.toLowerCase())
                                              ? activeIcon
                                              : textColor
                                          }
                                          me="18px"
                                        >
                                          {link.icon}
                                          
                                        </Box>
                                        <Text
                                          me="auto"
                                          pb={"3px"}
                                          textOverflow={"ellipsis"}
                                          overflowX="hidden"
                                          whiteSpace="nowrap"
                                          width="190px"
                                          color={
                                            activeRoute(link.path.toLowerCase())
                                              ? activeColor
                                              : textColor
                                          }
                                          fontWeight={
                                            activeRoute(link.path.toLowerCase())
                                              ? "bold"
                                              : "normal"
                                          }
                                        >
                                          <Tooltip hasArrow label={link.name}>
                                            {link.name}
                                          </Tooltip>
                                        </Text>
                                      </Flex>
                                    ) : (
                                      <Flex
                                        w="100%"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Box
                                          color={
                                            activeRoute(link.path.toLowerCase())
                                              ? activeIcon
                                              : textColor
                                          }
                                          me="18px"
                                        >
                                          {link.icon}
                                        </Box>
                                      </Flex>
                                    )}
                                    <Box
                                      w="4px"
                                      bg={
                                        activeRoute(link.path.toLowerCase())
                                          ? brandColor
                                          : brandColor
                                      }
                                      borderRadius="5px"
                                    />
                                  </HStack>
                                </Box>
                              ) : (
                                <Box>
                                  <HStack
                                    spacing={
                                      activeRoute(link.path.toLowerCase())
                                        ? "22px"
                                        : "26px"
                                    }
                                    py="5px"
                                    ps="10px"
                                  >
                                    <Text
                                      me="auto"
                                      color={
                                        activeRoute(link.path.toLowerCase())
                                          ? activeColor
                                          : inactiveColor
                                      }
                                      fontWeight={
                                        activeRoute(link.path.toLowerCase())
                                          ? "bold"
                                          : "normal"
                                      }
                                    >
                                      {link.name}
                                    </Text>
                                    <Box
                                      h="36px"
                                      w="4px"
                                      bg="brand.400"
                                      borderRadius="5px"
                                    />
                                  </HStack>
                                </Box>
                              )}
                            </NavLink>
                          )
                        );
                      })}
                  </Box>
                </Box>
              )}
            </Box>
          );
        }
      })
    );
  };
  
  //  BRAND
  return updatedRoute ? createLinks(updatedRoute) : [];
}

export default SidebarLinks;
