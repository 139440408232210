import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Text,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import "./style.css";
import PieChart from "components/charts/PieChart";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CountUpComponent from "components/countUpComponent/countUpComponent";
import TableView from "components/TabularView/TableView";
import Chart from "components/charts/LineChart.js";
import PieChart2 from "components/charts/PieChart2";
import { toast } from "react-toastify";
import { getApi } from "services/api";
import { putApi } from "services/api";
function CustomView({
  customTitle = "",
  customData = {},
  customViewCall,
  type = "lead",
  barData = {},
  data = [],
  pieData = [],
}) {
  const navigate = useNavigate();
  const [layout, setLayout] = useState({
    defaultViewName: "Bar View",
    defaultViewValue: "barView",
  });
  const userId = JSON.parse(localStorage.getItem("user"))?._id;

  const [updatedPieData, setUpdatedPieData] = useState(pieData);
  let viewTypeList = [
    {
      vieweName: "Bar View",
      viewValue: "barView",
    },
    {
      vieweName: "Table View",
      viewValue: "tabView",
    },
    {
      vieweName: "Pie View",
      viewValue: "PieView",
    },
  ];

  function onLayoutSelectHandler(e) {
    viewTypeList.forEach((item) => {
      if (item.viewValue === e.target.value) {
        setLayout({
          defaultViewValue: item.viewValue,
          defaultViewName: item.vieweName,
        });
      }
    });
    updateViewType(e.target.value);
  }
  async function updateViewType(viewType) {
    try {
      let res = await putApi(
        `api/custom-view/update/${customData.customView._id}`,
        {
          view_type: viewType,
        }
      );
      if (res) {
        customViewCall();
      }
    } catch (e) {
      toast.error("Something went wrong");
      
    }
  }
  useEffect(() => {
    if (type === "lead") {
      const color = ["#ebf5ff", "#eaf9e6", "#eaf9e6"];
      setUpdatedPieData((prev) => ({ ...prev, color }));
    }
  }, [type]);

  useEffect(() => {
    viewTypeList.forEach((item) => {
      if (item.viewValue === customData.customView.view_type) {
        setLayout({
          defaultViewValue: customData.customView.view_type,
          defaultViewName: item.vieweName,
        });
      }
    });
  }, [customData.customView.view_type]);

  return (
    <div className="customView">
      <Card>
        <Flex
          justifyContent={"space-between"}
          flexDirection={"column"}
          height={"100%"}
        >
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            {" "}
            <Heading size="md" pb={2}>
              {customData.customView.name}
            </Heading>
            <Select
              defaultValue={"active"}
              className=""
              onChange={(e) => {
                onLayoutSelectHandler(e);
              }}
              height={7}
              width={130}
              value={layout.defaultViewValue}
              style={{ fontSize: "14px", marginBottom: "10px" }}
            >
              {viewTypeList.map((item) => {
                return <option value={item.viewValue}>{item.vieweName}</option>;
              })}
            </Select>
          </Flex>
          {type === "lead" ? (
            <Flex justifyContent={"center"} alignItems={"center"}>
              <div>
                {" "}
                <Grid templateColumns="repeat(12, 1fr)" gap={2}>
                  <GridItem colSpan={{ base: 12, md: 6 }}>
                    <Box
                      backgroundColor={"#ebf5ff"}
                      borderRadius={"10px"}
                      cursor={"pointer"}
                      onClick={() => navigate("/crm/lead")}
                      p={2}
                      m={1}
                      textAlign={"center"}
                    >
                      <Heading size="sm" pb={3} color={"#1f7eeb"}>
                        Total Leads{" "}
                      </Heading>
                      <Text fontWeight={600} color={"#1f7eeb"}>
                        {/* <CountUpComponent targetNumber={tabularData?.length || 0} />{" "} */}
                        {customData?.totalLead || 0}
                      </Text>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 6 }}>
                    <Box
                      backgroundColor={"#fbf4dd"}
                      borderRadius={"10px"}
                      cursor={"pointer"}
                      onClick={() => navigate("/crm/lead", { state: "active" })}
                      p={2}
                      m={1}
                      textAlign={"center"}
                    >
                      <Heading size="sm" pb={3} color={"#a37f08"}>
                        Active Leads{" "}
                      </Heading>
                      <Text fontWeight={600} color={"#a37f08"}>
                        {customData?.activeLead ? customData?.activeLead : 0}
                      </Text>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 6 }}>
                    <Box
                      backgroundColor={"#ffeeeb"}
                      onClick={() => navigate("/crm/lead", { state: "pending" })}
                      borderRadius={"10px"}
                      cursor={"pointer"}
                      p={2}
                      m={1}
                      textAlign={"center"}
                    >
                      <Heading size="sm" pb={3} color={"#d6401d"}>
                        Pending Leads
                      </Heading>{" "}
                      <Text fontWeight={600} color={"#d6401d"}>
                        {customData?.pendingLead ? customData?.pendingLead : 0}
                      </Text>
                    </Box>
                  </GridItem>

                  <GridItem colSpan={{ base: 12, md: 6 }}>
                    <Box
                      backgroundColor={"#eaf9e6"}
                      borderRadius={"10px"}
                      cursor={"pointer"}
                      onClick={() => navigate("/crm/lead", { state: "sold" })}
                      p={2}
                      m={1}
                      textAlign={"center"}
                    >
                      {" "}
                      <Heading size="sm" pb={3} color={"#43882f"}>
                        Sold Leads
                      </Heading>{" "}
                      <Text fontWeight={600} color={"#43882f"}>
                        {customData?.soldLead ? customData?.soldLead : 0}
                      </Text>
                    </Box>
                  </GridItem>
                </Grid>
              </div>
            </Flex>
          ) : (
            ""
          )}

          <Flex
            justifyContent={"center"}
            overflow={"auto"}
            height={"50%"}
            mb={layout.defaultViewValue === "barView" && 10}
          >
            {layout.defaultViewValue !== "" && (
              <>
                {layout.defaultViewValue === "barView" ? (
                  <GridItem rowSpan={2} colSpan={{ base: 12, md: 12 }}>
                    {" "}
                    {/* Set colSpan to 12 for both base and md sizes */}
                    <Card>
                      <Chart dashboard={"dashboard"} data={barData.charData} />
                    </Card>
                  </GridItem>
                ) : (
                  <>
                    {layout.defaultViewValue === "tabView" ? (
                      <TableView tabularData={customData}></TableView>
                    ) : (
                      <PieChart2 leadData={pieData}></PieChart2>
                    )}
                  </>
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Card>
    </div>
  );
}

export default CustomView;
