import { constant } from "../constant";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getApi } from "services/api";
import { getDataFromStorage } from "utils/storage";
import qs from "qs";
import axios from "axios";
import { saveDataToStorage } from "utils/storage";
const SSOContext = createContext();

export const SSOProvider = ({ children }) => {
  const [codeVerifier, setCodeVerifier] = useState("");
  useEffect(() => {
    const localCodeVerifier = getDataFromStorage(constant.codeVerifier);
    if (localCodeVerifier) {
      setCodeVerifier(localCodeVerifier);
    }
  });
  // Function to fetch a new access token

  const fetchAccessToken = (refreshToken, authCode) => {
    const data = qs.stringify({
      grant_type: "refresh_token",
      client_id: constant.clientID,
      code: authCode,
      scope: constant.scope,
      refresh_token: refreshToken,
    });

    const config = {
      method: "post",
      url: constant.authCodeUrl,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        saveDataToStorage(constant.refreshToken, response.data.refresh_token);
        saveDataToStorage(
          constant.outlookAccessToken,
          response.data.access_token
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    // Fetch access token initially
    const refreshToken = localStorage.getItem(constant.refreshToken);
    const authCode = localStorage.getItem(constant.authCode);

    if (refreshToken && authCode) {
      fetchAccessToken(refreshToken, authCode);

      // Fetch access token every hour (3600000 milliseconds)
      const intervalId = setInterval(
        () => fetchAccessToken(refreshToken, authCode),
        3600000
      );

      // Cleanup function to clear the interval when component unmounts
      return () => clearInterval(intervalId);
    }
  }, []);
  const setCodeVerifierValue = (value) => {
    setCodeVerifier(value);
  };
  const valueToPass = {
    codeVerifier,
    setCodeVerify: setCodeVerifierValue,
  };
  return (
    <SSOContext.Provider value={valueToPass}>{children}</SSOContext.Provider>
  );
};

// Custom hook to access user data
export const SSORole = () => useContext(SSOContext);
