export const constant = {
    outlookAccessToken:'outlook_access_token',
    refreshToken:'refresh_token',
    responseType:'code',
    responseMode:'query',
    codeVerifier:'code-verifier',
    authCode:'auth-code',
    authorizationCode:'authorization_code',
    authCodeUrl:process.env.REACT_APP_AZURE_AUTH_CODE_URL,
    oauthUrl:process.env.REACT_APP_AZURE_OAUTH_URL,
    codeChallengeMethod:process.env.REACT_APP_AZURE_CODE_CHALLENGE_METHOD,
    baseUrl: process.env.REACT_APP_BASE_URL,
    clientID:process.env.REACT_APP_AZURE_CLIENT_ID,
    authority:process.env.REACT_APP_AZURE_AUTHORITY,
    redirectPath:process.env.REACT_APP_AZURE_REDIRECT_PATH,
    endpoint:process.env.REACT_APP_AZURE_GRAPH_ENDPOINT,
    scope:process.env.REACT_APP_AZURE_SCOPE,
    sessionType:process.env.REACT_APP_AZURE_SESSION_TYPE,
    recoilDuplicateAtomKeyCheckingEnabled:process.env.REACT_APP_AZURE_RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED,
    somethingWrong:'Something went wrong',
    add:'Add',
    folderExplorer:'File Explorer',
}
