import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { getApi } from "services/api";
import { toast } from "react-toastify";
import Spinner from "components/spinner/Spinner";
function TableView({ tabularData }) {
  const [isLoding, setIsLoding] = React.useState(false);
  const [fieldList, setFieldList] = React.useState();
  const textColor = useColorModeValue("gray.500", "white");

  async function getField() {
    setIsLoding(true);
    try {
      const result = await getApi(`api/custom-field/?moduleName=Leads`);
      let updateFieldList = result.data[0].fields.map((item) => {
        let list = { label: item.label, name: item.name };
        return list;
      });
      if (result.data[0]) {
        setFieldList((prev) => updateFieldList);
      } else {
        toast.error("Something went wrong");
      }
      setIsLoding(false);
    } catch (e) {
      setIsLoding(false);
      toast.error("Something went wrong");
    }
  }
  React.useEffect(() => {
    getField();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflowX: "auto",
        overflowY: "auto",
      }}
    >
      {!isLoding ? (
        <>
          {fieldList && tabularData && fieldList.length > 0 ? (
            <TableContainer>
              <Table variant="simple">
                <Thead style={{ borderWidth: "1" }}>
                  <Tr>
                    {tabularData.customView.fields.map((item) => {
                      let fieldName;
                      if (fieldList) {
                        fieldList.forEach((fieldInfo) => {
                          if (fieldInfo.name === item) {
                            fieldName = fieldInfo.label;
                          }
                        });
                      }

                      return <Th>{fieldName}</Th>;
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {tabularData.tabledata &&
                    tabularData.tabledata.map((item) => {
                      const entries = Object.entries(item).filter(
                        ([key, value]) => key !== "_id"
                      );

                      return (
                        <Tr>
                          {entries.map(([key, value]) => (
                            <Td key={key}>{value}</Td>
                          ))}
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <Text
              textAlign={"center"}
              width="100%"
              color={textColor}
              fontSize="sm"
              fontWeight="700"
            >
              -- No Data Found --
            </Text>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default TableView;
