import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const PieChart2 = (props) => {
  const { leadData } = props;let activeLength = leadData && leadData.length > 0 ? leadData?.filter(lead => lead?.leadStatus === "active")?.length : 0;
  let pendingLength = leadData && leadData.length > 0 ? leadData?.filter(lead => lead?.leadStatus === "pending")?.length : 0;
  let soldLength = leadData && leadData.length > 0 ? leadData?.filter(lead => lead?.leadStatus === "sold")?.length : 0;

  const series = [activeLength, pendingLength, soldLength];
  const scaledSeries = series?.map(value => {
    if (leadData?.length === 0) {
        return 0;
    } else {
        return value === 0 ? 0 : ((value * 100) / leadData?.length);
    }
});

const label=['Active', 'Pending', 'Sold']
const colors = ['#FFC300','#FF5733','#43882f'];
var options = {
  series: scaledSeries,
  chart: {
  width: 380,
  type: 'pie',
},
labels: label,
colors: colors,
responsive: [{
  breakpoint: 480,
  options: {
    chart: {
      width: 200
    },
    legend: {
      position: 'bottom'
    }
  }
}]
};


  return (
    <div>
      <ReactApexChart  options={options} series={scaledSeries} type="pie" height={320}width={380} />
    </div>
  );
};

export default PieChart2;
